<template>
  <v-layout row wrap>
    <v-flex xs12 class="mb-2 caption grey--text">{{
      $t("trigger_page.actions")
    }}</v-flex>
    <v-flex
      v-for="(action, actionIndex) in model"
      :key="`action-${actionIndex}`"
      xs12
    >
      <v-card class="mb-1 border-radius">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon class="sw-accent" @click="removeAction(actionIndex)">
            <v-icon small>delete</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="pt-0 pb-2 px-2">
          <v-text-field
            v-model="action.name"
            v-validate.disable="'required'"
            :error-messages="errors.collect(`action_name_${actionIndex}`)"
            :data-vv-name="`action_name_${actionIndex}`"
            :data-vv-as="$t('trigger_page.action_name')"
            :label="$t('trigger_page.action_name')"
          />
          <v-select
            v-model="action.key"
            :items="actions"
            item-text="text"
            item-value="key"
            v-validate.disable="'required'"
            :error-messages="errors.collect(`action_key_${actionIndex}`)"
            :data-vv-name="`action_key_${actionIndex}`"
            :data-vv-as="$t('trigger_page.action')"
            :label="$t('trigger_page.action')"
          />
          <v-switch
            v-model="action.enabled"
            :label="
              action.enabled ? $t('common.enabled') : $t('common.disabled')
            "
          />
          <div class="mb-2 caption grey--text">
            {{ $t("trigger_page.payload") }}
          </div>
          <v-card
            v-for="(param, paramIndex) in action.payload"
            :key="`param-${paramIndex}`"
            class="mb-1 border-radius"
          >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                icon
                class="sw-accent"
                :disabled="action.payload.length === 1"
                @click="removeParam(actionIndex, paramIndex)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-text class="pt-0 pb-2 px-2">
              <v-layout row>
                <v-flex xs6 class="pr-1">
                  <v-text-field
                    v-model="param.key"
                    v-validate.disable="'required'"
                    :error-messages="errors.collect(`param_key_${paramIndex}`)"
                    :data-vv-name="`param_key_${paramIndex}`"
                    :data-vv-as="$t('trigger_page.key')"
                    :label="$t('trigger_page.key')"
                  />
                </v-flex>
                <v-flex xs6 class="pl-1">
                  <v-text-field
                    v-model="param.value"
                    v-validate.disable="'required'"
                    :error-messages="
                      errors.collect(`param_value_${paramIndex}`)
                    "
                    :data-vv-name="`param_value_${paramIndex}`"
                    :data-vv-as="$t('trigger_page.value')"
                    :label="$t('trigger_page.value')"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <v-btn
            round
            class="mx-0 mt-1 mb-0 sw-secondary-bg sw-on-secondary text-none"
            @click="addParam(actionIndex)"
          >
            {{ $t("trigger_page.add_param") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-btn
        round
        class="mx-0 mt-1 mb-0 sw-secondary-bg sw-on-secondary text-none"
        @click="addAction"
      >
        {{ $t("trigger_page.add_action") }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["parentValidator"],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    actions() {
      return [
        {
          text: "Update group",
          key: "GroupUpdateAction",
        },
        {
          text: "Create message template",
          key: "MessageTemplateCreateAction",
        },
        {
          text: "Update message template",
          key: "MessageTemplateUpdateAction",
        },
        {
          text: "Update trigger",
          key: "TriggerUpdateAction",
        },
        {
          text: "Update user",
          key: "UserUpdateAction",
        },
      ];
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    addAction() {
      this.model.push({
        enabled: true,
        payload: [
          {
            key: "",
            value: "",
          },
        ],
      });
    },
    removeAction(actionIndex) {
      this.$delete(this.model, actionIndex);
    },
    addParam(actionIndex) {
      this.model[actionIndex].payload.push({
        key: "",
        value: "",
      });
    },
    removeParam(actionIndex, payloadIndex) {
      this.$delete(this.model[actionIndex].payload, payloadIndex);
    },
  },
};
</script>

<style lang="scss"></style>
