<template>
  <v-container class="general">
    <PageTitle :title="$t('trigger_list_page.title')" />
    <v-layout row wrap>
      <v-flex xs12 class="mb-3">
        <v-text-field
          v-model="groupTrigger.name"
          v-validate.disable="'required'"
          data-vv-name="name"
          :data-vv-as="$t('trigger_page.trigger_name')"
          hide-details
          :label="$t('trigger_page.trigger_name')"
        />
        <ErrorMessages :error-messages="errors.collect('name')" />
      </v-flex>
      <v-flex xs4 class="mb-3">
        <div class="mb-1 caption grey--text">
          {{ $t("trigger_page.scheduled_at") }}
        </div>
        <date-picker
          v-model="groupTrigger.scheduled_at"
          width="99%"
          :lang="datePickerLang"
          type="datetime"
          :first-day-of-week="1"
          format="MMMM DD, YYYY H:mm"
          :minute-step="5"
          :editable="false"
        />
        <ErrorMessages :error-messages="errors.collect('scheduled_at')" />
      </v-flex>
      <v-flex xs12>
        <v-switch
          v-model="groupTrigger.enabled"
          :label="
            groupTrigger.enabled ? $t('common.enabled') : $t('common.disabled')
          "
        />
      </v-flex>
      <v-flex xs12 class="mb-4">
        <Event v-model="groupTrigger.event_name" />
      </v-flex>
      <v-flex xs12 class="mb-4">
        <Conditions v-model="conditions" />
      </v-flex>
      <v-flex xs12 class="mb-5">
        <Actions v-model="actions" />
      </v-flex>
      <v-flex xs12>
        <v-btn
          round
          large
          class="my-0 ml-0 mr-3 white sw-accent text-none"
          :to="{ name: 'triggers' }"
        >
          {{ $t("common.cancel") }}
        </v-btn>
        <v-btn
          v-if="!triggerId"
          round
          large
          class="my-0 ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
          :loading="isLoading"
          @click="createGroupTrigger"
        >
          {{ $t("common.save") }}
        </v-btn>
        <v-btn
          v-if="triggerId"
          round
          large
          class="my-0 ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
          :loading="isLoading"
          @click="updateGroupTrigger"
        >
          {{ $t("common.save") }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import DatePicker from "vue2-datepicker";
import Event from "@/components/GroupTriggerPage/Event.vue";
import Conditions from "@/components/GroupTriggerPage/Conditions.vue";
import Actions from "@/components/GroupTriggerPage/Actions.vue";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    dateFormat: "YYYY-MM-DDTHH:mm:ssZ",
    groupTrigger: {
      enabled: true,
    },
    conditions: [],
    actions: [],
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    triggerId() {
      return this.$route.params.trigger_id;
    },
  },
  components: {
    DatePicker,
    Event,
    Conditions,
    Actions,
  },
  methods: {
    transformConditionsForSave(conditions) {
      if (!conditions || !conditions.length) {
        return [];
      }

      for (const condition of conditions) {
        if (!condition.return) continue;

        if (condition.return === "true") {
          condition.return = true;
        }

        if (condition.return === "false") {
          condition.return = false;
        }
      }

      return conditions;
    },
    transformActionsForSave(actions) {
      let transformedActions = [];

      for (const [i, action] of actions.entries()) {
        transformedActions[i] = {
          enabled: action.enabled,
          key: action.key,
          name: action.name,
          payload: {},
        };

        for (const param of action.payload) {
          transformedActions[i].payload[param.key] = param.value;
        }
      }

      return transformedActions;
    },
    transformActionsForEdit(actions) {
      let transformedActions = [];

      for (const [i, action] of actions.entries()) {
        let transformedAction = {
          enabled: action.enabled,
          key: action.key,
          name: action.name,
          payload: [],
        };

        for (const param in action.payload) {
          transformedAction.payload.push({
            key: param,
            value: action.payload[param],
          });
        }

        transformedActions[i] = transformedAction;
      }

      return transformedActions;
    },
    async createGroupTrigger() {
      this.errors.clear();

      if (!this.groupTrigger.event_name && !this.groupTrigger.scheduled_at) {
        this.errors.add({
          field: "scheduled_at",
          msg: "The Scheduled at field is required",
        });

        await this.$validator.validate();

        return;
      }

      const isValid = await this.$validator.validate();

      if (!isValid) return;

      this.isLoading = true;

      const specs = [
        this.groupId,
        {
          name: this.groupTrigger.name,
          scheduled_at: this.groupTrigger.scheduled_at
            ? moment(this.groupTrigger.scheduled_at).format(this.dateFormat)
            : null,
          enabled: this.groupTrigger.enabled,
          event_name: this.groupTrigger.event_name || "",
          condition: this.transformConditionsForSave(this.conditions),
          actions: this.transformActionsForSave(this.actions),
        },
      ];

      this.$api.groupTriggers.create(...specs).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.$store.dispatch("addNotification", {
            message: this.$t("trigger_page.register.create_success"),
          });
          this.$router.push({
            name: "triggers_edit",
            params: {
              trigger_id: response.data.data.id,
            },
          });
        },
        (error) => {
          this.isLoading = false;
          this.errorMessageShow(error);
        },
      );
    },
    getGroupTrigger() {
      if (!this.triggerId) return;

      this.isLoading = true;

      const params = [this.groupId, this.triggerId];

      this.$api.groupTriggers.get(...params).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.groupTrigger = response.data.data;
          this.conditions = response.data.data.condition;
          this.actions = this.transformActionsForEdit(
            response.data.data.actions,
          );
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async updateGroupTrigger() {
      this.errors.clear();

      if (!this.groupTrigger.event_name && !this.groupTrigger.scheduled_at) {
        this.errors.add({
          field: "scheduled_at",
          msg: this.$t("trigger_page.scheduled_at_error"),
        });

        await this.$validator.validate();

        return;
      }

      const isValid = await this.$validator.validate();

      if (!isValid) return;

      this.isLoading = true;

      const specs = [
        this.groupId,
        this.groupTrigger.id,
        {
          name: this.groupTrigger.name,
          scheduled_at: this.groupTrigger.scheduled_at
            ? moment(this.groupTrigger.scheduled_at).format(this.dateFormat)
            : null,
          enabled: this.groupTrigger.enabled,
          event_name: this.groupTrigger.event_name || "",
          condition: this.transformConditionsForSave(this.conditions),
          actions: this.transformActionsForSave(this.actions),
        },
      ];

      this.$api.groupTriggers.update(...specs).then(
        () => {
          this.isLoading = false;
          this.$store.dispatch("addNotification", {
            message: this.$t("trigger_page.register.update_success"),
          });
        },
        (error) => {
          this.isLoading = false;
          this.errorMessageShow(error);
        },
      );
    },
  },
  watch: {
    triggerId: {
      immediate: true,
      handler() {
        this.getGroupTrigger();
      },
    },
  },
};
</script>

<style lang="scss"></style>
