<template>
  <v-layout row wrap>
    <v-flex xs12 class="mb-2 caption grey--text">
      {{ $t("trigger_page.conditions") }}
    </v-flex>
    <v-flex xs12>
      <v-card
        v-for="(condition, conditionIndex) in model"
        :key="`condition-${conditionIndex}`"
        class="mb-1 border-radius"
      >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="sw-accent"
            @click="removeCondition(conditionIndex)"
          >
            <v-icon small>delete</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="pt-0">
          <v-layout row>
            <v-flex xs3 class="pr-1">
              <v-text-field
                v-model="condition.repository"
                v-validate.disable="'required'"
                :error-messages="errors.collect(`repository_${conditionIndex}`)"
                :data-vv-name="`repository_${conditionIndex}`"
                :data-vv-as="$t('trigger_page.repository')"
                :label="$t('trigger_page.repository')"
              />
            </v-flex>
            <v-flex xs1 class="px-1">
              <v-text-field
                v-model="condition.id"
                v-validate.disable="'required'"
                :error-messages="errors.collect(`id_${conditionIndex}`)"
                :data-vv-name="`id_${conditionIndex}`"
                :data-vv-as="$t('trigger_page.id')"
                :label="$t('trigger_page.id')"
              />
            </v-flex>
            <v-flex xs4 class="pl-1">
              <v-text-field
                v-model="condition.callable"
                v-validate.disable="'required'"
                :error-messages="errors.collect(`callable_${conditionIndex}`)"
                :data-vv-name="`callable_${conditionIndex}`"
                :data-vv-as="$t('trigger_page.callable')"
                :label="$t('trigger_page.callable')"
              />
            </v-flex>
            <v-flex xs3 class="pl-1">
              <v-text-field
                v-model="condition.arg1"
                :label="$t('trigger_page.arg1')"
              />
            </v-flex>
            <v-flex xs2 class="pl-1">
              <v-text-field
                v-model="condition.return"
                :label="$t('trigger_page.return')"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <v-btn
        round
        class="mx-0 mt-1 mb-0 sw-secondary-bg sw-on-secondary text-none"
        @click="addCondition"
      >
        {{ $t("trigger_page.add_condition") }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["parentValidator"],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    addCondition() {
      this.model.push({
        repository: null,
        id: null,
        callable: null,
      });
    },
    removeCondition(conditionIndex) {
      this.$delete(this.model, conditionIndex);
    },
  },
};
</script>

<style lang="scss"></style>
