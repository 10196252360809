<template>
  <v-layout row wrap>
    <v-flex xs12 class="mb-2 caption grey--text">
      {{ $t("trigger_page.event") }}
    </v-flex>
    <v-flex v-if="model" xs12>
      <v-card class="border-radius">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon class="sw-accent" @click="removeEvent">
            <v-icon small>delete</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="pt-0 pb-2 px-2">
          <v-select
            v-model="model"
            :items="events"
            item-text="text"
            item-value="event_name"
            :label="$t('trigger_page.event')"
          />
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex v-if="!model" xs12>
      <v-btn
        round
        class="mx-0 mt-1 mb-0 sw-secondary-bg sw-on-secondary text-none"
        @click="addEvent"
      >
        {{ $t("trigger_page.add_event") }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  inject: ["parentValidator"],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    events() {
      return [
        {
          text: "User subscribed",
          event_name: "UserGroupSubscribedEvent",
        },
      ];
    },
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    addEvent() {
      this.model = this.events[0].event_name;
    },
    removeEvent() {
      this.model = "";
    },
  },
};
</script>

<style lang="scss"></style>
